import React from "react";
import { Link } from "react-router-dom";


const ProductSection = () => {
  return (
    <section className="our-product">
      <div className="our-pro">
        <div className="pro-one">
          <h1 className="our-pro-title">OUR PRODUCT</h1>
          <h2 className="our-pro-subtitle">CONNECTING BRANDS WITH CREATORS</h2>
          <p className="our-pro-desc">
            Adintors is an innovative and user-friendly application that connects Businesses and Brands to Creators and Influencers for effective brand promotion and marketing. The features of the application simplifies the process of finding the right creators for your brand and streamlines the entire collaboration process.
          </p>
          <p className="our-pro-desc">
            For businesses, there are a wide range of creators to select and collaborate with. The Businesses can find the right fit for their brand promotion within their budget.

          </p>
          <p className="our-pro-desc">
            For creators, there is a systematic way to collaborate with various brands and monetize their content and get the right worth for their efforts.

          </p>
          <div className="btn-brand-creator">

            <Link to="OurProduct" className="btn-link">
              <button className="btn-style" aria-label="Brand">
                <span className="btn-text">Brand</span>
              </button>
            </Link>
            <Link to="OurProduct" className="btn-link">
              <button className="btn-style" aria-label="Creator">
                <span className="btn-text">Creator</span>
              </button>
            </Link>
          </div>
        </div>
        <div className="pro-two">
          <img
            className="hero-img"
            src="./images/about/creator-screen.png"
            alt="Mobile screen displaying Adintors application"
          />
        </div>
      </div>
    </section>
  );
};

export default ProductSection;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";

// Home Pages Import
import Adintors from "./pages/Adintors";


// Blog Import
import BlogGridView from "./pages/BlogGrid";
import BlogCategory from "./pages/Category";
import BlogArchive from "./pages/Archive";
import BlogDetails from "./pages/BlogDetails";

// Service
import ServiceOne from "./pages/ServiceOne";
import ServiceTwo from "./pages/ServiceTwo";
import ServiceDetails from "./pages/ServiceDetails";

// Project
import ProjectGridOne from "./pages/ProjectGridOne";
import ProjectGridTwo from "./pages/ProjectGridTwo";
import ProjectGridThree from "./pages/ProjectGridThree";
import ProjectGridFive from "./pages/ProjectGridFive";
import ProjectGridFour from "./pages/ProjectGridFour";
import ProjectDetails from "./pages/ProjectDetails";

// Pages
import AboutUs from "./pages/AboutUs";
import OurOffice from "./pages/OurOffice";
import OurClients from "./pages/OurClients";
import Team from "./pages/Team";
import TeamDetails from "./pages/TeamDetails";
import CaseStudy from "./pages/CaseStudy";
import CaseDetails from "./pages/CaseStudyDetails";
import Testimonials from "./pages/Testimonials";
import PricingTable from "./pages/PricingTable";
import Typography from "./pages/Typography";
import Contact from "./pages/Contact";
import ErrorPage from "./pages/404";
import ComingSoon from "./pages/ComingSoon";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
// import Career from "./component/career/Career";
import Creators from "./pages/Creators";
import Brand from "./pages/Brand";
import OurProduct from "./pages/OurProduct";
import AboutAdintors from "./component/about/AboutAdintors";
import ProductSection from "./pages/ProductSection";

// Css Import
import "./assets/scss/app.scss";

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route
            path={"/"}
            element={<Adintors />}
          />

          <Route
            path={ "/digital-agency"}
            element={<Adintors />}
          />

          {/* <Route path={ "/home-startup"} element={<HomeStartup />}/> */}

          {/* Blogs */}
          <Route
            path={ "/blog-grid/"}
            element={<BlogGridView />}
          />
          <Route
            path={ "/category/:slug"}
            element={<BlogCategory />}
          />
          <Route
            path={ "/archive/:slug"}
            element={<BlogArchive />}
          />
          <Route
            path={ "/blog-details"}
            element={<BlogDetails />}
          />

          {/* Service */}
          <Route
            path={ "/service-one/"}
            element={<ServiceOne />}
          />
          <Route
            path={ "/service-two/"}
            element={<ServiceTwo />}
          />
          <Route
            path={ "/service-details/:slug"}
            element={<ServiceDetails />}
          />

          {/* Project  */}
          <Route
            path={ "/project-grid-one"}
            element={<ProjectGridOne />}
          />
          <Route
            path={ "/project-grid-two"}
            element={<ProjectGridTwo />}
          />
          <Route
            path={ "/project-grid-three"}
            element={<ProjectGridThree />}
          />
          <Route
            path={ "/project-width-one"}
            element={<ProjectGridFour />}
          />
          <Route
            path={ "/project-width-two"}
            element={<ProjectGridFive />}
          />
          <Route
            path={ "/project-details/:slug"}
            element={<ProjectDetails />}
          />

          {/* Pages  */}
          <Route
            path={ "/about-us"}
            element={<AboutUs />}
          />
          <Route
            path={ "/our-office"}
            element={<OurOffice />}
          />
          <Route
            path={ "/our-clients"}
            element={<OurClients />}
          />
          <Route path={ "/team"} element={<Team />} />
          <Route
            path={ "/team-details/:slug"}
            element={<TeamDetails />}
          />
          <Route
            path={ "/case-study"}
            element={<CaseStudy />}
          />
          <Route
            path={ "/case-details/:slug"}
            element={<CaseDetails />}
          />
          <Route
            path={ "/testimonials"}
            element={<Testimonials />}
          />
          <Route
            path={ "/pricing-table"}
            element={<PricingTable />}
          />
          <Route
            path={ "/typography"}
            element={<Typography />}
          />
          <Route
            path={ "/contact"}
            element={<Contact />}
          />
          <Route
            path={ "/404"}
            element={<ErrorPage />}
          />
          <Route
            path={ "/coming-soon"}
            element={<ComingSoon />}
          />
          <Route
            path={ "/privacy-policy"}
            element={<PrivacyPolicy />}
          />
          <Route
            path={ "/terms-use"}
            element={<TermsOfUse />}
          />
{/* 
          <Route
            path={ "/Career"}
            element={<Career />}
          /> */}
                    <Route
            path={ "/Creators"}
            element={<Creators />}
          />
            <Route
            path={ "/Brand"}
            element={<Brand />}
          />
          <Route
            path={ "/OurProduct"}
            element={<OurProduct />}
          />
                    <Route
            path={ "/AboutAdintors"}
            element={<AboutAdintors />}
          />

          <Route
            path={ "/ProductSection"}
            element={<ProductSection />}
          />


          
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

export default App;


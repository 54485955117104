import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
        Your Message has been successfully sent.
        </Alert>
    )
}

const FormTwo = () => {

    const form = useRef();

    const [ result, showresult ] = useState(false);
    const [ formDetails, setFormDetails ] = useState({
        name:"",
        email: "",
        phone: "",
        desc: ""
    })


    // const sendEmail = (e) => {
    //     e.preventDefault();
    
    //     emailjs.sendForm('service_ol1glnk', 'template_du94k9v', form.current, 'ojrbErZOHf3uNLero')
    //       .then((result) => {
    //           console.log(result.text);
    //       }, (error) => {
    //           console.log(error.text);
    //       });
    //       form.current.reset();
    //       showresult(true);
    //   };

    //     setTimeout(() => {
    //         showresult(false); 
    //     }, 5000);

    


    function sendEmail(e) {
        e.preventDefault()
    const formEle = document.querySelector("form");
    const formDatab = new FormData(formEle);
    setFormDetails({
        name:"",
        email: "",
        phone: "",
        desc: ""
    })
    fetch(
      "https://script.google.com/macros/s/AKfycbxfcO4oOu2xLsMK2jdrQuepA7gOBNGALS4ZGLQ4UBdJR-ujZsuRbaL4DPBxZCPEV7WM/exec",
      {
        method: "POST",
        body: formDatab
      }
    )
      .then((res) => res.json())
      .then((data) => {

        
      })
      
      .catch((error) => {
        console.log(error);
      });
      }


    return (
        <form ref={form} onSubmit= {(e) => {
            sendEmail(e) 
            // console.log(formDetails)
            }} className="axil-contact-form">
        <div className="form-group">
            <label>Name</label>
            <input type="text" className="form-control" name="Name" value={formDetails.name} required onChange={(e) => setFormDetails({...formDetails , name: e.target.value})} />
        </div>
        <div className="form-group">
            <label>Email</label>
            <input type="email" className="form-control" name="Email" required value={formDetails.email} onChange={(e) => setFormDetails({...formDetails , email: e.target.value})} />
        </div>
        <div className="form-group">
            <label>Phone</label>
            <input type="tel" className="form-control" name="Phone" required value={formDetails.phone} onChange={(e) => setFormDetails({...formDetails , phone: e.target.value})} />
        </div>
        <div className="form-group mb--40">
            <label>How can we help you?</label>
            <textarea className="form-control" name="Description" rows="4" value={formDetails.desc} onChange={(e) => setFormDetails({...formDetails , desc: e.target.value})} />
            
        </div>
        <div className="form-group">
            <button type="submit" className="axil-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn">Submit</button>
        </div>
        <div className="form-group">
            {result ? <Result /> : null}
        </div>

    </form>
    )
}

export default FormTwo;
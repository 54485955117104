import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";



const ProjectOne = ({ parentClass }) => {

  return (
    <>
      <div
        className={`section section-padding-2 ${
          parentClass ? parentClass : ""
        }`}
      >
        <div className="container">
          <SectionTitle
            title="Our Passion"
            textAlignment="heading-center mb--40"
            textColor=""
          />
          <div className="isotope-button isotope-project-btn">
            <div className="about-us">
              <div className="section-heading text-black  heading-left mb-0">
                <h6 className="text-center lh-base">
                We are passionate about creating innovative and effective solutions that solve exciting problems. Our team of experts is driven by a deep love for technology and a desire to bring a wonderful revolution in the world. We believe that every project, big or small, deserves our full attention and dedication. From alpha to omega, we are committed to delivering excellence and exceeding expectations. At Adintors, our passion for innovation and technology fuels our drive to push the boundaries and constantly improve our skills and knowledge. We are excited to share our passion with you and help bring your ideas to life and your creativity to the world.
                </h6>
              </div>
            </div>
          </div>
        </div>
        <ul className="shape-group-7 list-unstyled">
          <li className="shape shape-1">
            <img
              src={ "/images/others/circle-3.png"}
              alt="circle"
            />
          </li>
          <li className="shape shape-3">
            <img
              src={ "/images/others/bubble-1.png"}
              alt="Line"
            />
          </li>
        </ul>
      </div>
    </>
  );
};

export default ProjectOne;

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

const Nav = () => {
  const location = useLocation();

  const scrollToSection = (e, sectionId) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    section?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const isHome = location.pathname ===  '/';
  const isSmoothScrollEnabled = !['/blog-grid', '/contact','/about-us'].includes(location.pathname);

  const renderHomeLink = isHome ? (
    <Link to={ '/'} onClick={scrollToTop}>
      Home
    </Link>
  ) : (
    <Link to={ '/'}>Home</Link>
  );
console.log(location.pathname)
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li className={location.pathname=="/"?"active":"none"}>{renderHomeLink}</li>
        {/* <li>
          {isSmoothScrollEnabled ? (
            <Link to={ '/#aboutus'} onClick={(e) => scrollToSection(e, 'aboutus')}>
              About Us
            </Link>
          ) : (
            <a href={ '/#aboutus'}>About Us</a>
          )}
        </li> */}
         <li className={location.pathname=="/about-us"?"active":"none"}><Link to="/about-us">About Us</Link></li>



        {/* <li>
          {isSmoothScrollEnabled ? (
            <Link to={ '/'} onClick={(e) => scrollToSection(e, 'career')}>
              Career
            </Link> 
          ) : (
            <a href={ '/career'}>Career</a>
          )}
        </li> */}
        <li className={location.pathname=="/blog-grid"?"active":"none"}>
          <Link to={ '/blog-grid'}>Blogs </Link>
        </li>



        <li className={`${location.pathname=="/OurProduct"?"active":"none"} 'our-product-nav'>`} style={{
          // backgroundColor:'#32475C',
          // color:'#32475C',
          // padding:'0px 12px',
          // borderRadius:'30px'
        }}>
          <Link className='' to={ '/OurProduct'}>Our Product</Link>
        </li>




        {/* <li>
          <Link to={ '/contact'}>Contact Us</Link>
        </li> */}
        {/* <li>
          <Link to={ '/Creators'}>Creators</Link>
        </li>
        <li>
          <Link to={ '/Brand'}>Brand</Link>
        </li> */}
        

      </ul>
    </nav>
  );
};

export default Nav;

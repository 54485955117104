import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProcessOne from '../component/process/ProcessOne';
import AboutThree from '../component/about/AboutThree';
import AboutFour from '../component/about/AboutFour';
import AboutFive from '../component/about/AboutFive';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import { Link } from 'react-router-dom';
import FormTwo from '../component/contact/FormTwo';




const AboutUs = () => {

    return (
        <>
       <>
            <SEO title="About Us" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />


        <div className='about-us-background'>
        <div className="about-us-container">

                    <div className="breadcrum-About">
            
                <div className="breadcrumb">
                    <ul className="list-unstyled">
                        <li>
                            <Link to={ "/"}>Home</Link>
                        </li>
                        <li className="active"> About Us</li>
                    </ul>
                </div>
            
        </div>
    </div>
        <div className='about-us-data'>
                    <p>
                        The name 'Adintors' is an amalgamation of advertisements, influencers, and creators.
                    </p>
                <p>
                    We are coming up with an exciting new application called Adintors that will revolutionise the way brands and businesses manage their advertisements and promotional activities.
                </p>
                <p>
                    Adintors is an innovative platform that connects Brands and Businesses to Influencers and Creators for effective brand promotion and marketing. The platform is designed to meet the needs of both Businesses and Creators. For businesses, we offer a wide range of features to help them identify and collaborate with the right content creator. For creators, we provide a streamlined way to find new brands to partner with and monetize their content.
                </p>
                <p>
                    Our application connects brands and businesses with a diverse pool of social media content creators who can help promote their products or services to a wider audience. It is a platform where businesses can find the perfect social media creator for their specific marketing requirements within their specific budget.
                </p>
                <p>
                    The application is intuitive, making it easy for businesses to navigate and contact the right influencers for their campaigns. It provides detailed analytics and reporting, allowing users to track their campaign's performance.
                </p>
                <p>
                    Adintors is designed to make it super easy for businesses to find and connect with creators and influencers who match their target audience, marketing goals, and most importantly their budget. We understand that every business is unique, therefore, we provide a user-friendly application that simplifies the process of finding the right influencers for their brand and streamlines the entire collaboration process all based on our excellent database.
                </p>

            <div className='about-our-passion'>
                <h2 className='passion'>Our Passion</h2>
                <h1 className='passion-main-heading'>
                When you love what you do, it shows in the quality of your work. 
                </h1>
            </div>
            <p>
                Building the world's best is our passion. We take pride in developing exceptional products.                 
             </p>
                <p>
                    Our team of dedicated engineers, developers, and designers work tirelessly to create platforms that stand beyond expectations. We believe that every project, big or small, deserves our full attention and dedication. From alpha to omega, we are committed to bringing up excellence.    
                </p>
                <p>
                At Adintors, our passion for innovation and technology fuels our drive to push the boundaries and constantly improve our skills and knowledge. 
                </p>
    </div>

        </div>

        {/* <div className="section section-padding"> */}
                <div className="container-contact-us">
                    <div className="row">
                        <div className="col-xl-5 col-lg-5 pt-6">
                            <div className="contact-form-box shadow-box mb--30">
                                <h3 className="title">Contact us</h3>
                                <FormTwo />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7 offset-xl-1 mt-5">

                            <div className='row'>
                                
                                
            
                            </div>
                            <div className="contact-info mb--30">
                            {/* <h4 className="title">Phone</h4>
                                <h5 className="phone-number"><a href="tel:1234567890">(079) 4917 6631</a></h5> */}
                                <h4 className="title">Email</h4>
                                <p> Mon-Fri, &nbsp; 9:00 am to 6:00 pm</p>
                                <p>Our support team will get back to in 48-hours during standard business hours.</p>
                                <h5 className="phone-number"><a href="mailto:info@adintors.com">info@adintors.com</a></h5>
                            </div>
                           
                           
                            <div className="contact-info mb--30">
                                <h4 className="title">Address</h4>
                                <p>Mon-Fri, 9:00 am to 6:00 pm.</p>
                                <h5 className="phone-number"><a href="https://www.google.com/maps/place/Adintors/@23.0339257,72.5021766,17z/data=!3m1!4b1!4m6!3m5!1s0x395e9b3b8db55f1d:0x66c6655a05586518!8m2!3d23.0339208!4d72.5047515!16s%2Fg%2F11ssr9vhn2?entry=ttu">B-702, Shilp Corporate Park, Rajpath Rangoli Road, Bodakdev, Ahmedabad, Gujarat 380054.</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="list-unstyled shape-group-12">
                    {/* <li className="shape shape-1"><img src={ "/images/others/bubble-2.png"} alt="Bubble" /></li> */}
                    <li className="shape shape-2"><img src={ "/images/others/bubble-1.png"} alt="Bubble" /></li>
                    <li className="shape shape-3"><img src={ "/images/others/circle-3.png"} alt="Circle" /></li>
                </ul>
            {/* </div> */}



                
                
                {/* <CtaLayoutOne /> */}
            <FooterOne parentClass="" />
            
            </main>
        </>
        </>
    )
}

export default AboutUs;
import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import AboutOne from "../component/about/AboutOne";
import BlogOne from "../component/blog/BlogOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ProjectOne from "../component/project/ProjectOne";
import BannerThree from "../component/banner/BannerThree";
import Career from "../component/career/Career";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import AboutAdintors from "../component/about/AboutAdintors";
import ProductSection from "./ProductSection";

const Adintors = () => {
  return (
    <>
      <SEO title="Adintors" />  
        <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BannerThree />
        <AboutAdintors />
        <ProductSection />
        <BlogOne />
        <FooterOne />
      </main>
    </>
  );
};

export default Adintors;

import React from 'react';

const CtaLayoutOne = () => {

    return (

        <div className="section call-to-action-area">
            <div className="container">

                <div className="call-to-action">
                    <div className="section-heading heading-light">
                        <span className="subtitle">Our Work</span>
                        <h2 className="title">We love what we do</h2>
                        <h6 className="text-white lh-base">Building visionary and cutting-edge software solutions is our passion, and we take pride in delivering exceptional products to our clients. Our team of dedicated developers, designers, and engineers work tirelessly to create platforms that not only meet but exceed our client's expectations. We believe that when you love what you do, it shows in the quality of your work, and we are committed to delivering the best possible results for our clients. Thank you for choosing Adintors, where passion and expertise come together to create amazing solutions.</h6>
                        
                    </div>
                    
                </div>
            </div>
            <ul className="list-unstyled shape-group-3">
                    <li className="shape shape-1"><img src={ "/images/others/line-1.png"} alt="shape" /></li>
                    <li className="shape shape-2"><img src={ "/images/others/bubble-4.png"} alt="shape" /></li>
                </ul>
        </div>
    )

}

export default CtaLayoutOne;
import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import BlogSidebar from '../component/blog/BlogSidebar';
import BlogGridOne from '../component/blog/BlogGridOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import { Link } from 'react-router-dom';


const BlogGridView = () => {

    return (
        <>
            <SEO title="Blog Grid" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                {/* <BreadCrumbOne 
                title="Blogs"
                page="Blogs "
                /> */}

                
                <div className='about-us-background'>
                        <div className="about-us-container">
                            <div className="breadcrum-About">
                                <div className="breadcrumb">
                                    <ul className="list-unstyled">
                                        <li>
                                            <Link to={"/"}>Home</Link>
                                        </li>
                                        <li className="active">Blogs</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
              











                
                {/* <div className="section-padding-equal"> */}
                    {/* <div className="container-blog-section"> */}
                       {/* <div className="row row-40"> */}
                            <div className="main-blog-section">
                                <BlogGridOne />
                            </div>
                            {/* <div className="col-lg-4">
                                <BlogSidebar />
                            </div> */}
                       </div>
                    {/* </div> */}
                {/* </div> */}
            {/* </div> */}
                {/* <CtaLayoutOne /> */}
            <FooterOne parentClass="" />
          
            </main>
        </>
    )
}

export default BlogGridView;
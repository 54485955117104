import React from "react";
import { Link } from "react-router-dom";
import Logo from '../../elements/logo/Logo';





// const getServiceData = ServiceData;

const FooterOne = ({ parentClass }) => {
  return (
    
    <footer className={`footer-area ${parentClass}`}>
      <div className="container" style={{
        maxWidth:'100%'
      }}
      >



{/* creator who influence section started  */}
      <div className="footer-heading-background">
        <h1 className="heading-creator">CREATORS WHO <br /> INFLUENCE</h1>
      </div>
{/* creator who influence section Over  */}

{/* footer main section start  */}

<section className="footer-section">
      <div className="footer-main-part">
        <div className="main-footer-img-black">
          <img src="/images/dark-logo.svg" alt="Logo" /> 
          </div>
        

        <div className="menu-footer">
          <div className="footer-menu-main">
            <h3 className="footer-menu-title">Address</h3>
            <p className="footer-menu-list">
              B-702, Shilp Corporate Park, Rajpath Rangoli Rd, Bodakdev,
              Ahmedabad, Gujarat 380054.
            </p>
            <p className="footer-menu-list">
                <a href="mailto:info@adintors.com">info@adintors.com</a>
            </p>
            {/* <p className="footer-menu-list">(079) 4917 6631</p> */}
            
          </div>
          <div className="footer-menu-main">
            <h3 className="footer-menu-title">Company</h3>
            <a href="/about-us">
        
              <p className="footer-menu-list">  About Us</p>
              </a>
              <br />
            {/* <a href="/careers">
              <p className="footer-menu-list"> Careers </p>
                </a> */}
          </div>
          {/* <div className="footer-menu-main">
            <h3 className="footer-menu-title">Contact Us</h3>
            <p className="footer-menu-list">+91 7949176631</p>
          </div> */}
        </div>
      </div>
    </section>


{/* footer main section close  */}


        {/* <div className="footer-main">
          <div className="row" style={{
          }}>
            <div className="col-xl-7 col-lg-7 ">
              <div className="address-list  d-flex row  ">
                <div className="address col-lg-3">
                  <h6 className="title">Phone</h6>
                  <p>(079) 4917 6631</p>
                </div>
                <div className="address col-lg-3">
                  <h6 className="title">Mail</h6>
                  <p>info@adintors.com</p>
                </div>

                <div className="address col-lg-5 ">
                  <h6 className="title">Address</h6>
                  <p style={{
                    width:'90%'
                  }}>
                    B-702, Shilp Corporate Park, Rajpath Rangoli Road, Bodakdev,
                    Ahmedabad, Gujarat 380054.
                  </p>
                </div>
              </div>
            
            </div>
            <div className="col-xl-4 col-lg-4">
              <div className="row">

                <div className="col-sm-6 col-6">
                  <div className="footer-widget">
                    <h6 className="widget-title">Company</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link to={ "/"}>Home</Link>
                        </li>
                        <li>
                          <Link to={ "/blog-grid"}>
                          Blogs 
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-6">
                  <div className="footer-widget">
                    <h6 className="widget-title">Support</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link to={ "/contact"}>
                            Contact Us
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
         

      {/* footer lets connect section start  */}

    <div
      className="container-footeArea">
        <div className="heading-connect">
        <h1 className="lets-heading">LET’S CONNECT HERE</h1>
      <img src="/images/about/down-arrow.svg" alt="Down Arrow" className="down-arrow" />
        </div>

      <div className="button-container">
            <a href="mailto:info@adintors.com">
                <button className="custom-button">info@adintors.com</button>
          </a>
            <a href="https://www.instagram.com/adintors/" target="_blank" rel="noopener noreferrer">
                <button className="custom-button">Instagram</button>
          </a>
          <a href="https://in.linkedin.com/company/adintors" target="_blank" rel="noopener noreferrer">
        <button className="custom-button">Linkedin</button>
        </a>
      </div>
    </div>
{/* footer lets connect section end  */}

        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()}. All rights reserved by{" "}
                  <a href="https://adintors.com/">Adintors Pvt Ltd</a>.
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-bottom-link">
                <ul className="list-unstyled">
                  <li>
                    <Link to={ "/privacy-policy"}>
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={ "/terms-use"}>
                    Terms of Service
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
